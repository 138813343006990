.content {
    width: 1200px;
    padding: 30px;
    max-width: 100%;
}

.realContent {
    padding-top: 10px;
    border-radius: 5px;
    background-color: white;
}