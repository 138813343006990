.content {
    width: 1200px;
    padding: 30px;
}

.realContent {
    background-color: white;
    padding: 15px;
    border-radius: 5px;
}

.cond_content {
    line-height: 1.1;
}
