.societyImage{
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

.a_wrapper {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit color from parent */
    pointer-events: auto; /* Ensure the link is clickable */
}

.a_wrapper:hover {
    /* Optional: Remove hover effects */
    text-decoration: none;
    color: inherit;
}